import { useMediaQuery } from '@vueuse/core';

export default function useScreen() {
  const screenSizes = {
    laptopSmall: '1366px',
    tabletLarge: '1280px',
    tablet: '1024px',
    tabletSmall: '768px',
    retina: '640px',
    mobileLarge: '480px',
    mobile: '380px',
  };

  const isLaptopSmall = useMediaQuery(`(max-width: ${screenSizes.laptopSmall})`);
  const isTabletLarge = useMediaQuery(`(max-width: ${screenSizes.tabletLarge})`);
  const isTablet = useMediaQuery(`(max-width: ${screenSizes.tablet})`);
  const isTabletSmall = useMediaQuery(`(max-width: ${screenSizes.tabletSmall})`);
  const isRetina = useMediaQuery(`(max-width: ${screenSizes.retina})`);
  const isMobileLarge = useMediaQuery(`(max-width: ${screenSizes.mobileLarge})`);
  const isMobile = useMediaQuery(`(max-width: ${screenSizes.mobile})`);

  return {
    isLaptopSmall,
    isTabletLarge,
    isTablet,
    isTabletSmall,
    isRetina,
    isMobileLarge,
    isMobile,
  };
};